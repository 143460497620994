import React from "react"
import app from '../firebase/firebase';
import { getAnalytics, logEvent } from "firebase/analytics";
import Seo from '../components/Seo/Seo';
import Container from '../components/Container/Container';
import * as classes from './about.module.css';
import Scroll from '../components/Scroll/Scroll';

const About = () => {

    React.useEffect(() => {
      if (!app) {
        return
      }
      const analytics = getAnalytics(app);
      logEvent(analytics, 'visited_download');
    }, [])

  return (
    <div className={classes.aboutBackground}>
    <Scroll />
    <Container>
        <Seo title={`About`}/>
        <div className={classes.aboutContainer}>
                    <span className={classes.title}>About Us</span>
                    <section>
                        <p>
                            As a mother of three, I understand that my kids need the freedom and independence to be able to stay in touch with their friends and family. However, as a parent with a computer science background, I’m also well aware of the dangers and inside risks associated with social networks. What’s worse is that children are often the most targeted and yet most vulnerable to these threats.
                        </p>
                        <p>
                            kChat was created with these concerns in mind. It offers kids a completely safe platform to chat with friends—and stay in contact with us parents too! The user interface is simple and easy for kids to navigate while also allowing them to express themselves creatively and in a fun and exciting way. There’s certainly nothing boring about this app!
                        </p>
                        <p>
                            While working on this project, COVID-19 hit us all and turned us into socially distanced and isolated individuals. This made kChat’s potential to make a difference so much greater. The messaging app allows children to stay in close contact with their friends and relatives they no longer get to see and stay up to date with their teachers and school work.
                        </p>
                        <p>
                            But most importantly, parents have peace of mind knowing that their kids are safe and protected at all times. Children never have to give out their phone numbers, and they can only talk to people we approve. It’s definitely a win for both parents and kids!
                        </p>
                    </section>
        </div>
    </Container>
    <footer className={classes.fotterAbout}> 
    </footer> 
    </div>
  )
}

export default About
